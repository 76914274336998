
import { Component, Vue } from "vue-property-decorator";

import MyCommentComponent from "../components/MyComment.vue";

import type { Comment } from "../store";

@Component({
  components: {
    MyCommentComponent
  }
})
export default class MyCommentsPage extends Vue {
  comments: Comment[] = [];

  get personalPostsData() {
    return this.$store.getters["posts/personalData"];
  }

  created() {
    this.fetchComments();
  }

  fetchComments() {
    this.$store.dispatch("posts/fetchMyComments").then(({ data }) => {
      this.comments = data.data;
    });
  }

  fetchPosts() {
    this.$store.dispatch("posts/fetchPersonal").then(({ data }) => {
      if (!this.personalPostsData) this.$store.commit("posts/setPersonalData", [...data.data]);
      else this.$store.commit("posts/setPersonalData", [...this.personalPostsData, ...data.data]);
    });
  }
}
