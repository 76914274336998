
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

import type { Comment } from "../store";

@Component({
  filters: {
    created_at_from_now(value, appLanguage): string {
      if (!value) return "";

      return moment(value)
        .locale(appLanguage)
        .fromNow();
    }
  }
})
export default class CommentComponent extends Vue {
  @Prop(Object) readonly comment!: Comment;

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  showComment() {
    this.$router.push({
      name: "posts.show",
      params: { id: `${this.comment.post_id}` },
      query: { comment_id: `${this.comment.id}` }
    });
  }
}
